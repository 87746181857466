import {
    API_WATCHLIST_UNDERLYINGS_STATE_URL,
    API_WATCHLIST_UNDERLYINGS_URL,
    API_WATCHLISTED_WIKIFOLIOS_URL,
    API_WATCHLIST_WIKIFOLIOS_STATE_URL,
    createApiAddWatchlistWikifoliosUrl,
    createApiRemoveWatchlistUnderlyingsUrl,
    createApiRemoveWatchlistWikifoliosUrl,
} from "src/consts/urls";
import { IMutationOptions, useMutation } from "src/hooks/react-query/use-mutation";
import { useQuery } from "src/hooks/react-query/use-query";
import { IUser } from "src/types/common";
import { EnumWikifolioStatus } from "src/types/common-enum";
import { IErrorResponse } from "./types";

export interface IResponse {
    isSuccess: boolean;
    error: string | null;
}

export interface IAddWatchlistWikifoliosRequest {
    intendedInvestment?: number;
}

export const useAddWatchlistWikifoliosMutation = (
    wikifolioId: string,
    options?: IMutationOptions<IResponse, IErrorResponse, IAddWatchlistWikifoliosRequest>
) => useMutation<IResponse, IErrorResponse, IAddWatchlistWikifoliosRequest>(createApiAddWatchlistWikifoliosUrl(wikifolioId), options);

export const useRemoveWatchlistWikifoliosMutation = (wikifolioId: string, options?: IMutationOptions<void, IErrorResponse, void>) => {
    return useMutation<void, IErrorResponse, void>(createApiRemoveWatchlistWikifoliosUrl(wikifolioId), {
        method: "DELETE",
        ...options,
    });
};

export type IGetWatchlistStateResponse = Record<string, { canBeFollowed: boolean; isFollowed: boolean }>;

export interface IGetWatchlistWikiloliosStateRequest {
    wikifolioIds: ReadonlyArray<string>;
}

export const useGetWatchlistWikifoliosStateMutation = (
    options?: IMutationOptions<IGetWatchlistStateResponse, void, IGetWatchlistWikiloliosStateRequest>
) => useMutation<IGetWatchlistStateResponse, void, IGetWatchlistWikiloliosStateRequest>(API_WATCHLIST_WIKIFOLIOS_STATE_URL, options);

interface IAddWatchlistUnderlyingsRequest {
    isin: string;
}

export const useAddWatchlistUnderlyingsMutation = (options?: IMutationOptions<IResponse, void, IAddWatchlistUnderlyingsRequest>) =>
    useMutation<IResponse, void, IAddWatchlistUnderlyingsRequest>(API_WATCHLIST_UNDERLYINGS_URL, options);

export const useRemoveWatchlistUnderlyingsMutation = (underlyingIsin: string, options?: IMutationOptions<IResponse, void, void>) =>
    useMutation<IResponse, void, void>(createApiRemoveWatchlistUnderlyingsUrl(underlyingIsin), {
        method: "DELETE",
        ...options,
    });

export interface IGetWatchlistUnderlyingsStateRequest {
    underlyingIsins: ReadonlyArray<string>;
}

export const useGetWatchlistUnderlyingsStateMutation = (
    options?: IMutationOptions<IGetWatchlistStateResponse, void, IGetWatchlistUnderlyingsStateRequest>
) => useMutation<IGetWatchlistStateResponse, void, IGetWatchlistUnderlyingsStateRequest>(API_WATCHLIST_UNDERLYINGS_STATE_URL, options);

interface ITableFigure {
    creationDate?: string;
    id: string;
    value: string;
    // TODO: this should be an Enum
    type: number;
}
export interface IWatchlistedWikifolio {
    wikifolioId: string;
    symbol: string;
    wikifolioShortDescription: string;
    invested: boolean;
    trader: Pick<IUser, "id" | "firstName" | "lastName" | "nickName" | "isDeleted">;

    figures: ITableFigure[];
    status: EnumWikifolioStatus;
}
export interface IWatchlistedWikifoliosResponse {
    results: IWatchlistedWikifolio[];
    totalResults: number;
}

export interface IWatchlistedWikifoliosKeyFigure {
    id: string;
    label: string;
}

interface IUseGetWatchlistedWikifoliosParams {
    keyFigures: IWatchlistedWikifoliosKeyFigure[];
    offset?: number;
    limit?: number;
}

export const useGetWatchlistedWikifoliosQuery = ({ keyFigures, offset, limit }: IUseGetWatchlistedWikifoliosParams) =>
    useQuery<IWatchlistedWikifoliosResponse>(API_WATCHLISTED_WIKIFOLIOS_URL, {
        queryParams: new URLSearchParams([
            ...keyFigures.map(keyFigure => ["selectedFigures", keyFigure.id]),
            ["offset", String(offset)],
            ["limit", String(limit)],
        ]),
    });

export const useToggleIsSelfInvestedMutation = (wikifolioId: string, options?: IMutationOptions<IResponse, IErrorResponse, boolean>) =>
    useMutation<IResponse, IErrorResponse, boolean>(`${API_WATCHLISTED_WIKIFOLIOS_URL}/${wikifolioId}/invested`, {
        method: "PUT",
        ...options,
    });
